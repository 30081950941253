<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="18">
            <div class="caz-blocks-sarcho-title">
                <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">{{$t('message.deals')}}</div>
                <div class="filter_filial">
                    <select-from-filial
                        class="ml-5"
                        :size="'large'"
                        :select_only="false"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.from_filial.title"
                        v-model="filterForm.from"
                    >
                    </select-from-filial>
                    <select-to-filial
                        class="ml-5"
                        :size="'large'"
                        :show_all="1"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.to_filial.title"
                        v-model="filterForm.to"
                    >
                    </select-to-filial>
                    <el-select v-model="filterForm.cargo_type" :placeholder="$t('message.cargo_type')" class="ml-5" size="mini" clearable>
                        <el-option
                            v-for="item in cargoTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="ml-5" v-if="authUser.client_id">
                    <span class="align-items-center align-self-center pr-2">
                        <span class="el-tag el-tag--light" style="font-size:16px" :class="mode ? 'price__day' : 'price__night blue__night'">
                            {{ $t("message.total_amount") }}: {{ total | formatMoney(2) }}
                        </span>
                    </span>
                </div>
                <div class="ml-5" v-if="authUser.client_id">
                    <span class="align-items-center align-self-center pr-2">
                        <span class="el-tag el-tag--light" style="font-size:16px" :class="mode ? 'price__day' : 'price__night blue__night'">
                            {{ $t("message.paid") }}: {{ total_paid | formatMoney(2) }}
                        </span>
                    </span>
                </div>
                <div class="block-sarche">
                    <div class="header__search ml-4"></div>
                </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="6" class="d-flex text-right"  :class="mode ? 'button__settingsday' : 'button__settingsnight'">
            <div style="margin-left: auto;"> </div>
            <!-- <el-button @click="addModalCreate(true, 'parcel')" class="btu-color mr-2" size="mini">{{ $t("message.parcel") }}</el-button> -->
            <el-button @click="addModalCreate(true, 'commercial')" class="btu-color mr-2" size="mini">{{ $t("message.commertial_cargo") }}</el-button>
            <crm-create-and-column-settings
                :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                :allowCreate="false"
                :permission="$options.name"
                :columns="columns"
                @c-change="updateColumn"
            >
            </crm-create-and-column-settings>
        </el-col>
        </el-row>
      </div>

      <!-- Top Button Filters -->
      <filter-table ref="filter_table" @updateActiveFilter="updateActiveFilter" />
      
      <!-- end ifon-add-title-block -->
        <div class="card-table-header table-crm-smart custom__scroll" v-loading="loadingData" element-loading-spinner="el-icon-loading">
            <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered layout__initial">
              <thead>
                  <tr>
                      <th class="w50p" v-if="columns.id.show">
                          {{ columns.id.title }}
                      </th>

                      <th class="w50p" v-if="columns.airway_bill_numbers.show">
                          {{ columns.airway_bill_numbers.title }}
                      </th>

                      <th v-if="columns.user.show">
                          {{ $t('message.responsible') }}
                      </th>

                      <th v-if="columns.delivery_deal_id.show">
                          {{  columns.delivery_deal_id.title }}
                      </th>

                      <th v-if="columns.tracking_code.show">
                            {{ columns.tracking_code.title }}
                      </th>

                      <th v-if="columns.payer.show">
                          {{ columns.payer.title }}
                      </th>

                      <th v-if="columns.sender.show">
                          {{ columns.sender.title }}
                      </th>

                      <th v-if="columns.sender_address.show">
                          {{ columns.sender_address.title }}
                      </th>

                      <th v-if="columns.reciever.show">
                          {{ columns.reciever.title }}
                      </th>

                      <th v-if="columns.reciever_address.show">
                          {{ columns.reciever_address.title }}
                      </th>

                      <th v-if="columns.partner.show">
                          {{ columns.partner.title }}
                      </th>

                      <th v-if="columns.all_weight.show">
                          {{ columns.all_weight.title }}
                      </th>

                      <th v-if="columns.total.show">
                          {{ columns.total.title }}
                      </th>

                      <th v-if="columns.paid_money.show">
                          {{ columns.paid_money.title }}
                      </th>

                      <th v-if="columns.debt.show">
                          {{ columns.debt.title }}
                      </th>

                      <th v-if="columns.from_filial.show">
                          {{ columns.from_filial.title }}
                      </th>

                      <th v-if="columns.to_filial.show">
                          {{ columns.to_filial.title }}
                      </th>

                      <th v-if="columns.direction_tariff.show">
                          {{ columns.direction_tariff.title }}
                      </th>

                      <th v-if="columns.products.show">
                          {{ columns.products.title }}
                      </th>

                      <th v-if="columns.begin_date.show">
                          {{ columns.begin_date.title }}
                      </th>

                      <th v-if="columns.end_date.show">
                          {{ columns.end_date.title }}
                      </th>

                      <th v-if="columns.comment.show">
                          {{ columns.comment.title }}
                      </th>

                      <th v-if="columns.created_at.show">
                          {{ columns.created_at.title }}
                      </th>

                      <th v-if="columns.updated_at.show">
                          {{ columns.updated_at.title }}
                      </th>

                      <th v-if="columns.settings.show">
                          {{ columns.settings.title }}
                      </th>
                  </tr>

                  <tr class="filter_sorche">
                      <th v-if="columns.id.show">
                          <el-input
                              clearable
                              size="mini"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.id"
                              :placeholder="columns.id.title"
                              class="id_input"
                          ></el-input>
                      </th>

                      <th v-if="columns.delivery_deal_id.show">
                          <el-input
                              clearable
                              size="mini"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.delivery_deal_id"
                              :placeholder="columns.delivery_deal_id.title"
                              class="id_input"
                          ></el-input>
                      </th>

                      <th v-if="columns.user.show">
                          <select-users
                              :placeholder="$t('message.responsible')"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.user_id"
                          ></select-users>
                      </th>

                      <th v-if="columns.tracking_code.show">
                          <crm-input
                              :placeholder="$t('message.tracking_code')"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.tracking_code"
                          ></crm-input>
                      </th>

                      <th v-if="columns.payer.show">
                            <select-client
                                :size="'medium'"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="columns.payer.title"
                                v-model="filterForm.payer_client_id"
                            >
                            </select-client>
                      </th>

                      <th v-if="columns.sender.show">
                            <select-client
                                :size="'medium'"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="columns.sender.title"
                                v-model="filterForm.client_id"
                            >
                            </select-client>
                      </th>

                      <th v-if="columns.sender_address.show">
                          <crm-input
                              :placeholder="columns.sender_address.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              :disable="true"
                              v-model="filterForm.sender_address"
                          ></crm-input>
                      </th>

                      <th v-if="columns.reciever.show">
                            <select-client
                                :size="'medium'"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="columns.reciever.title"
                                v-model="filterForm.to_client_id"
                            >
                            </select-client>
                      </th>

                      <th v-if="columns.reciever_address.show">
                          <crm-input
                              :placeholder="columns.reciever_address.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              :disable="true"
                              v-model="filterForm.reciever_address"
                          ></crm-input>
                      </th>

                      <th v-if="columns.partner.show">
                            <select-client
                                :size="'medium'"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="columns.partner.title"
                                :query="{clientType_id: 1}"
                                v-model="filterForm.partner_client_id"
                            >
                            </select-client>
                      </th>

                      <th v-if="columns.all_weight.show"></th>
                      <th v-if="columns.total.show"></th>
                      <th v-if="columns.paid_money.show"></th>
                      <th v-if="columns.debt.show"></th>

                      <th v-if="columns.from_filial.show">
                        <select-from-filial
                            :size="'medium'"
                            :select_only="false"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.from_filial.title"
                            v-model="filterForm.from_filial_id"
                        >
                        </select-from-filial>
                      </th>
                      <th v-if="columns.to_filial.show">
                        <select-to-filial
                            :size="'medium'"
                            :select_only="false"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.to_filial.title"
                            v-model="filterForm.to_filial_id"
                        >
                        </select-to-filial>
                      </th>

                      <th v-if="columns.direction_tariff.show">
                            <select-tariff
                              :size="'large'"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              :placeholder="$t('message.directionTariff')"
                              :id="filterForm.direction_tariff_id"
                              :from_filial_id="filterForm.from_filial_id"
                              :to_filial_id="filterForm.to_filial_id"
                              :show_all="true"
                              v-model="filterForm.direction_tariff_id"
                              >
                            </select-tariff>
                      </th>

                      <th v-if="columns.products.show">
                          <crm-input
                              disabled
                              :placeholder="columns.products.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.products"
                          ></crm-input>
                      </th>

                     <th v-if="columns.begin_date.show">
                          <crm-date-picker
                              :placeholder="columns.begin_date.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.begin_date"
                          ></crm-date-picker>
                      </th>

                      <th v-if="columns.end_date.show">
                          <crm-date-picker
                              :placeholder="columns.end_date.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.end_date"
                          ></crm-date-picker>
                      </th>
                      
                      <th v-if="columns.comment.show">
                         
                      </th>
                      
                      <th v-if="columns.created_at.show">
                          <crm-date-picker
                              :placeholder="columns.created_at.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.created_at"
                          ></crm-date-picker>
                      </th>

                      <th v-if="columns.updated_at.show">
                          <crm-date-picker
                              :placeholder="columns.updated_at.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.updated_at"
                          ></crm-date-picker>
                      </th>

                      <th
                          class="settinW"
                          v-if="columns.settings.show"
                      ></th>
                  </tr>
              </thead>
              <transition-group name="flip-list" tag="tbody">
                  <tr
                      v-for="deal in list"
                      :key="deal.id"
                      class="cursor-pointer"
                      :class="rowClass(deal)"
                  >
                      <td v-if="columns.id.show">
                        <div style="font-weight: 700;">
                            <i v-if="deal.cargo_type === 'parcel'" class="el-icon-message" style="color: #0017ff;"></i> 
                            <i v-else class="el-icon-box" style="color: #7f6918;"></i> 
                            {{ deal.id }}
                        </div>
                      </td>

                      <td v-if="columns.airway_bill_numbers.show">
                          {{ deal.airway_bill_numbers ? deal.airway_bill_numbers.join(', ') : '' }}
                      </td>

                      <td v-if="columns.user.show">
                          {{ deal.user ? deal.user.name : '' }}
                      </td>

                      <td v-if="columns.delivery_deal_id.show">
                          {{ deal.delivery_deal_id }}
                      </td>
                      
                      <td v-if="columns.tracking_code.show">
                          {{ deal.tracking_code }}
                      </td>

                      <td v-if="columns.payer.show">
                        <span v-if="deal.payer">
                            {{ deal.payer.custom_id ? ('ID: ' + deal.payer.custom_id) : '' }} <br>
                            {{ deal.payer.name}} <br>
                            {{ deal.payer.phone}}
                        </span>
                      </td>

                      <td v-if="columns.sender.show">
                          {{ deal.client ? (deal.client.custom_id ? ('ID: ' + deal.client.custom_id) : '') : '' }} <br>
                          {{ deal.client ? deal.client.name : '' }} <br>
                          {{ deal.client ? deal.phone : '' }}
                      </td>

                      <td v-if="columns.sender_address.show">
                          {{ (deal.deal_type_id === 1) ? '' : deal.client_deal_address }}
                      </td>

                      <td v-if="columns.reciever.show">
                            <span v-if="deal.reciever">
                                {{ deal.reciever ? (deal.reciever.custom_id ? ('ID: ' + deal.reciever.custom_id) : '') : '' }} <br>
                                {{ deal.reciever ? deal.reciever.name : '' }}<br>
                                {{ deal.reciever ? deal.to_client_phone : '' }}
                            </span>
                      </td>

                      <td v-if="columns.reciever_address.show">
                          {{ (deal.deal_type_id === 1) ? deal.client_deal_address : deal.to_client_deal_address }}
                      </td>

                      <td v-if="columns.partner.show">
                            <span v-if="deal.partner">
                                {{ deal.partner ? (deal.partner.custom_id ? ('ID: ' + deal.partner.custom_id) : '') : '' }} <br>
                                {{ deal.partner ? deal.partner.name : '' }}<br>
                                {{ deal.partner ? deal.partner_phone : '' }}
                            </span>
                      </td>

                      <td v-if="columns.all_weight.show">
                           {{ deal.all_weight | formatNumber(1)}} kg
                            / 
                           {{ (deal.all_weight * $kg_to_pound) | formatNumber(1)}} lbs
                      </td>

                      <td v-if="columns.total.show">
                          {{ calculateDealTotal(deal) | formatMoney(1) }}
                      </td>

                      <td v-if="columns.paid_money.show">
                          {{ deal.paid_money | formatMoney(1) }}
                      </td>

                      <td v-if="columns.debt.show">
                          {{ (calculateDealTotal(deal) - deal.paid_money) | formatMoney(1) }}
                      </td>

                      <td v-if="columns.from_filial.show">
                          {{deal.from_filial ? deal.from_filial.name : '' }}
                      </td>

                      <td v-if="columns.to_filial.show">
                          {{deal.to_filial ? deal.to_filial.name : '' }}
                      </td>

                      <td v-if="columns.direction_tariff.show">
                          {{deal.directionTariff ? deal.directionTariff.name : '' }}
                      </td>

                      <td v-if="columns.products.show">
                          <span @click="showItems(deal)" style="cursor: pointer;">
                              {{ $t('message.show') }} <span class="el-icon-arrow-right"></span>
                          </span>
                      </td>

                      <td v-if="columns.begin_date.show">
                          {{ deal.begin_date }}
                      </td>

                      <td v-if="columns.end_date.show">
                          {{ deal.end_date }}
                      </td>

                      <td v-if="columns.comment.show">
                          {{ deal.comment }}
                      </td>

                      <td v-if="columns.created_at.show">
                          {{ deal.created_at }}
                      </td>

                      <td v-if="columns.updated_at.show">
                          {{ deal.updated_at }}
                      </td>

                      <td
                          v-if="columns.settings.show"
                          class="settings-td"
                      >
                        <div class="flight__edit">
                            <a class="ml-1" @click="edit(deal)" v-if="permissions.some(per => per.slug == 'deals.edit')">
                                <i class="fa fa-eye"></i>
                            </a>
                            <a class="ml-1" @click="edit(deal, true)" v-if="permissions.some(per => per.slug == 'deals.edit')">
                                <i class="fa-regular fa-pen-to-square"></i>
                            </a>
                        </div>
                      </td>
                  </tr>
              </transition-group>

            </table>
            <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogItemsVisible" width="60%" @closed="empty()" >
                <div v-loading="loadingProducts">
                  <el-table :data="dealProducts" show-summary :summary-method="getSummaries" border>
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                          {{ scope.row.product ? scope.row.product.name : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="quantity" :label="$t('message.quantity')"></el-table-column>
                    
                    <el-table-column prop="incoming_quantity" :label="$t('message.incoming_quantity')">
                        <template slot-scope="scope">
                          {{ scope.row.incoming_quantity }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="remainder" :label="$t('message.remainder')">
                        <template slot-scope="scope">
                          {{ scope.row.quantity - scope.row.incoming_quantity }}
                        </template>
                    </el-table-column>

                   <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                   <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
                    <el-table-column prop="price" :label="$t('message.price')">
                        <template slot-scope="scope">
                          {{ scope.row.price | formatNumber(1) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="currency" :label="$t('message.currency')">
                        <template slot-scope="scope">
                          {{ (scope.row.currency ? scope.row.currency.code : '')}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.total')">
                        <template slot-scope="scope">
                          {{ (parseFloat(scope.row.currency_rate) * parseFloat(scope.row.weight) * parseFloat(scope.row.price)).toFixed(2) }} 
                          {{ system_currency }}
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
            </el-dialog>
            <div class="my___pagination">
              <crm-pagination
                  :class="mode ? 'pagination__day' : 'pagination__night'"
                  @c-change="updatePagination"
                  :pagination="pagination"
              ></crm-pagination>
            </div>
        </div>

        <el-drawer
            :visible.sync="drawerDealCreate"
            :with-header="false"
            size="95%"
            class="body_scroll"
            ref="drawerDealCreate"
            @opened="drawerOpened('drawerDealCreateChild')"
            @closed="drawerClosed('drawerDealCreateChild')"
        >
            <AppModal
                ref="drawerDealCreateChild"
                :selectedDeal="selectedDeal"
                :dealCreate="dealCreate"
                :dealEdit="dealEdit"
                :cargo_type="cargo_type"
                drawer="drawerDealCreate"
            />
        </el-drawer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/el-pagination";
import filterTable from "./components/filterTable";
import AppModal from "./components/add-modal";
import selectClient from "@/components/inventory/select-client";
import selectTariff from "@/components/selects/select-direction-tariff";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";

import list from "@/utils/mixins/list";
import {mapGetters, mapActions} from 'vuex'
import { formatMoney, formatNumber } from "@/filters/index";
import SelectUsers from '../../components/inventory/select-users';

export default {
    name: "deal",
    mixins: [list],
    components: {
        Pagination,
        AppModal,
        filterTable,
        selectClient,
        selectTariff,
        selectFromFilial,
        selectToFilial,
        SelectUsers
    },

    data() {
        return {
            dealEdit: false,
            dialogItemsVisible: false,
            loadingData: false,
            dealCreate: true,
            form: {},
            system_currency: process.env.VUE_APP_CURRENCY,
            selectedDeal: null,
            cargo_type: '',
            drawerDealCreate: false,
            loadingProducts: false,
            cargoTypes: [
                {
                    value: 'parcel',
                    label: this.$t('message.parcels')
                }, 
                {
                    value: 'commertial_cargo',
                    label: this.$t('message.commertial_cargo')
                },
            ]
        };
    },
    computed: {
        ...mapGetters({
            permissions: "auth/permissions",
            dealProducts: "dealProducts/dealProducts",
            list: "deals/list",
            columns: "deals/columns",
            pagination: "deals/pagination",
            filter: "deals/filter",
            sort: "deals/sort",
            authUser: "auth/user", 
            total: "deals/total",
            total_paid: "deals/total_paid",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
        watchMainFilials(){
            return this.filterForm.from + this.filterForm.to;
        }
    },
    watch:{
        filterForm: {
            handler: async function(newVal, oldVal) {
              await this.updatePagination({ key: "page", value: 1 });
              await this.updateFilter(newVal);
              this.debouncedFetchData();
            },
            deep: true,
            immediate: true
        },
        watchMainFilials: {
            handler: async function(newVal, oldVal) {
              if (newVal) {
                let query = {from: this.filterForm.from, to: this.filterForm.to};
                if(this.$refs['filter_table']){
                    this.$refs['filter_table'].updateFilterOptions({...query, show_totals: true});
                }
              }
              else{
                if(this.$refs['filter_table']){
                    this.$refs['filter_table'].updateFilterOptions({show_totals: true});
                }
              }
            },
            deep: true,
            immediate: true
        },

    },
    methods: {
        ...mapActions({
            updateList: "deals/index",
            setPagination: "deals/setPagination",
            updateSort: "deals/updateSort",
            updateFilter: "deals/updateFilter",
            updateColumn: "deals/updateColumn",
            toggleColumns: "deals/toggleColumns",
            updatePagination: "deals/updatePagination",
            show: "deals/show",
            empty: "dealProducts/empty",
            delete: "deals/destroy",
            updateDealProducts: "dealProducts/show",
            refreshData: "deals/refreshData",
        }),
        rowClass(deal){
            let color = '';
            if(deal.paid_money > 0){
                color = 'mm__row__yellow';
                if(_.round(deal.paid_money, 2) == this.calculateDealTotal(deal)){
                    color = 'mm__row__green';
                }else if (_.round(deal.paid_money, 2) > this.calculateDealTotal(deal)){
                    color = 'mm__row__danger';
                }
            }
            return color;
        },

        calculateDealTotal(deal){
            let total = deal.product_total + deal.total_services_price;
            if(deal.cargo_type != 'parcel' && deal.delivery_price && deal.shipping_service){
                total += (parseFloat(deal.delivery_price) * parseFloat(deal.all_weight))
            }   
            return _.round(total, 2);
        },
        edit(model, edit=false){
            this.selectedDeal = model;
            this.dealCreate = false;
            this.drawerDealCreate = true;
            this.dealEdit = edit;
        },

        addModalCreate(dealCreate, cargo_type = '') {
            this.cargo_type = cargo_type;
            this.selectedDeal = null;
            this.dealCreate = dealCreate;
            this.drawerDealCreate = true;
        },

        closeAddModel() {
            this.drawerDealCreate = false;
            if(this.reloadList === true){
                this.debouncedGetList();
            }
        },

        updateActiveFilter(placement){
            if(placement === 'waiting_payment' || this.filterForm.placement === 'waiting_payment'){
                this.toggleColumns(['all_weight', 'total', 'paid_money', 'debt', 'direction_tariff'])
            }
            this.$set(this.filterForm, 'placement', placement)
        },
        
        showItems(model) {
            this.dialogItemsVisible = true;
            this.loadingProducts = true;
            let placement = this.filterForm.placement ? this.filterForm.placement : '';
            this.updateDealProducts({id: model.id, placement: placement, show: false})
                .then((res) => {
                    this.loadingProducts = false;
                })
                .catch((err) => {
                    this.loadingProducts = false;
                });
        },

        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            if(data && data.length > 0){

                columns.forEach((column, index) => {
                    if (index === 1) {
                        sums[index] = this.$t('message.total');
                        return;
                    }
                    if (column.label === this.$t('message.weight_in_kg')) {
                        const mapped_arr = data.map(item => item.weight);
                        let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                        sums[index] = formatNumber(total, 1) + ' ' + this.$t('message.kg');
                        return;
                    }
                    if (column.label === this.$t('message.weight_in_pounds')) {
                        const mapped_arr = data.map(item => item.weight_in_pounds);
                        let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                        sums[index] = formatNumber(total, 1) + ' ' + 'lbs';
                        return;
                    }
                    if (column.label === this.$t('message.quantity')) {
                        const mapped_arr = data.map(item => item.quantity);
                        let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                        sums[index] = formatNumber(total, 1);
                        return;
                    }
                    if (column.label === this.$t('message.incoming_quantity')) {
                        const mapped_arr = data.map(item => item.incoming_quantity);
                        let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                        sums[index] = formatNumber(total, 1);
                        return;
                    }

                    if (column.label === this.$t('message.total')) {
                        const mapped_arr = data.map(item => item.price * item.currency_rate * item.weight);
                        let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                        sums[index] = formatNumber(total, 1) + process.env.VUE_APP_CURRENCY;
                        return;
                    }
                    
                });
            }
            return sums;
        },
    },
    
};
</script>
<style>
    .space__between {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .space__between span {
        cursor: pointer;
        font-size: 18px;
    }
    .mm__row__green {
        background-color: rgb(83, 180, 83);
    }

    .mm__row__yellow {
        background-color: rgb(236, 179, 73);
    }

    .mm__row__danger {
        background-color: rgb(255, 75, 52);
    }

    .filter_filial{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

</style>
